<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <h3>Sql veritabanı tablolar arası ilişkiler</h3>
        <h6>Tablolar ve tablolar arasındaki ilişkiler gösterilmektedir</h6>
        <hr>
        <template v-for="(table_name, table_name_index) in d_sqlDatabaseList.table_order">
          <ul>
            <li>
              <span style="color: royalblue; cursor: pointer;" @click="d_tableDetails.show = true; d_tableDetails.table_name = table_name">
                {{ table_name }}
              </span>
              <br>
              <small>
                [ {{ d_sqlDatabaseList.tables[table_name].info }} ]
              </small>
              <ul>
                <template v-for="(table_var, table_var_index) in d_sqlDatabaseList.tables[table_name].variable">
                  <li v-if="table_var.relation_table">
                    .{{ table_var.name }} = <span style="color: seagreen; cursor: pointer;" @click="d_tableDetails.show = true; d_tableDetails.table_name = table_var.relation_table">{{ table_var.relation_table }}</span>.{{ table_var.relation_table_variable }}
                  </li>
                </template>
              </ul>
            </li>
          </ul>
        </template>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <b-modal v-if="d_tableDetails.show" v-model="d_tableDetails.show" centered class="modal-success" @ok="d_tableDetails.show = false" ok-variant="success" hide-footer size="xl">
      <b-row>
        <b-col cols="12">
          <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': d_tableDetails.table_name}"></sql-database>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button variant="danger" @click="d_tableDetails.show = false">Kapat</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'DatabaseTableInfrastructure',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    SqlDatabase,
  },
  props: {},
  data () {
    return {
      d_tableDetails: {
        'show': false
      },
      d_sqlDatabaseList: require('@/options/sql_database').options
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style type="text/css">

</style>

